/* eslint-disable */
import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import Select from "react-select";

//import translation function for language translation
import { useTranslation } from "react-i18next";

//import api requiremnts
import * as url from "urlhelpers/UrlHelper";
import { getData, putData } from "utils/Gateway";
import SurveyFeedbackThanksModal from "./Modal/SurveyFeedbackThanksModal";

const SurveyFeedbackForm = () => {
  const { t } = useTranslation(); //for translation

  const params = useParams();
  const history = useHistory();

  const searchParams = new URLSearchParams(location.search);

  const color = searchParams.get("color");
  const mailid = searchParams.get("mail");

  const [surveyUser, setSurveyUser] = useState(null);
  const [surveyQuestionList, setSurveyQuestionList] = useState([]);
  const [surveyAnswers, setSurveyAnswers] = useState([]);
  const [isSubmited, setIsSubmited] = useState(false);

  //function for get surveyfeedback
  const getSurveyFeedback = async () => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_SURVEY_FEEDBACK +
        `?surveyid=${params.id}`;

      const response = await getData(requestUrl);

      console.log(response);
      if (response.status) {
        if (response.data) {
          setSurveyUser(response.data.user);
          if (response.data.surveyresponsestatus == "1") {
            setSurveyAnswers(response.data.survey.questions);
            setSurveyQuestionList([]);
            setIsSubmited(true);
          } else {
            const updatedQuestions = response.data.survey.questions.map(
              (question) => ({
                ...question,
                answer: "",
                value: "",
              })
            );
            setSurveyQuestionList(updatedQuestions);
            setSurveyAnswers([]);
            setIsSubmited(false);
          }
        } else {
          setSurveyUser(null);
          setSurveyQuestionList([]);
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for submit survey
  const surveySubmitHandler = async () => {
    try {
      let surveyFeedbackData = {
        user: surveyUser,
        surveyfeedbackanswers: surveyQuestionList,
        color: color,
        mailid: mailid,
      };

      console.log("surveyFeedbackData=========>", surveyFeedbackData);

      if (params.id) {
        let requestUrl =
          url.API_BASEURL + url.API_UPDATE_SURVEY_FEEDBACK + `/${params.id}`;

        const response = await putData(requestUrl, surveyFeedbackData);

        console.log("response submit feedback =====>", response);

        if (response.status) {
          let myModal = new bootstrap.Modal(
            document.getElementById("submitSurveyFeedback")
          );
          myModal.show();
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for after modal close
  const closeModalHandler = () => {
    getSurveyFeedback();
    history.push("/");
  };

  useEffect(() => {
    if (params.id) {
      getSurveyFeedback();
    }
  }, [params.id]);

  return (
    <div className="surver-container p-4 bg-gray-500 shadow-xs">
      <h3 className="mb-3">{t("Requesting feedback")}</h3>

      {/* --------- survey question section start -------- */}
      {surveyQuestionList.length > 0 ? (
        <div className="bg-white p-3">
          {surveyQuestionList.map((surveyQuestion, index) => {
            return (
              <div className="form-group mb-3" key={index}>
                {/* ----- question section start ------ */}
                <label htmlFor="participating">
                  {surveyQuestion ? surveyQuestion.question : ""}
                </label>
                {/* ----- question section end ------ */}

                {/* ----- question type start ------ */}
                {surveyQuestion.type == "Rating" ? (
                  <div className="feedback_rating">
                    <ul className="list_stye_none">
                      {["1", "2", "3", "4", "5"].map((star, index2) => {
                        const isActive =
                          surveyQuestion.answer !== "" &&
                          Number(star) <= Number(surveyQuestion.answer);
                        return (
                          <li
                            key={index2}
                            className={
                              isActive
                                ? "active k-cursor-pointer"
                                : "k-cursor-pointer"
                            }
                            onClick={() => {
                              const value = star;
                              setSurveyQuestionList((prevList) => {
                                const updatedList = [...prevList];
                                updatedList[index].answer = value;
                                return updatedList;
                              });
                            }}
                          >
                            {isActive ? (
                              <span className="material-icons-outlined">
                                star
                              </span>
                            ) : (
                              <span className="material-icons-outlined">
                                star_outline
                              </span>
                            )}
                          </li>
                        );
                      })}
                      {/* <li className="active k-cursor-pointer">
                        <span className="material-icons-outlined">star</span>
                      </li>
                      <li className="k-cursor-pointer">
                        <span className="material-icons-outlined">
                          star_outline
                        </span>
                      </li>
                      <li className="k-cursor-pointer">
                        <span className="material-icons-outlined">
                          star_outline
                        </span>
                      </li>
                      <li className="k-cursor-pointer">
                        <span className="material-icons-outlined">
                          star_outline
                        </span>
                      </li> */}
                    </ul>
                  </div>
                ) : surveyQuestion.type == "Text" ? (
                  <input
                    type="text"
                    id="participating"
                    className="form-control"
                    placeholder="Answer"
                    value={surveyQuestion.answer}
                    onChange={(e) => {
                      const value = e.target.value;
                      setSurveyQuestionList((prevList) => {
                        const updatedList = [...prevList];
                        updatedList[index].answer = value;
                        return updatedList;
                      });
                    }}
                  />
                ) : surveyQuestion.type == "Single Select" ? (
                  <Select
                    className="nosearch_select"
                    options={surveyQuestion.options}
                    value={surveyQuestion.value}
                    onChange={(selectedOption) => {
                      setSurveyQuestionList((prevList) => {
                        const updatedList = [...prevList];
                        updatedList[index].value = selectedOption;
                        updatedList[index].answer = selectedOption.value;
                        return updatedList;
                      });
                    }}
                  />
                ) : surveyQuestion.type == "Multi Select" ? (
                  <Select
                    isMulti
                    className="nosearch_select"
                    options={surveyQuestion.options}
                    value={surveyQuestion.value}
                    onChange={(selectedOptions) => {
                      const values = selectedOptions.map(
                        (option) => option.value
                      );
                      const convertedString = values.join(", ");
                      setSurveyQuestionList((prevList) => {
                        const updatedList = [...prevList];
                        updatedList[index].value = selectedOptions;
                        updatedList[index].answer = convertedString;
                        return updatedList;
                      });
                    }}
                  />
                ) : surveyQuestion.type == "Comments" ? (
                  <textarea
                    name="comment"
                    id="comment"
                    cols="30"
                    rows="4"
                    className="form-control"
                    placeholder={t("Give comment")}
                    value={surveyQuestion.answer}
                    onChange={(e) => {
                      const value = e.target.value;
                      setSurveyQuestionList((prevList) => {
                        const updatedList = [...prevList];
                        updatedList[index].answer = value;
                        return updatedList;
                      });
                    }}
                  ></textarea>
                ) : null}
                {/* ----- question type end ------ */}
              </div>
            );
          })}
          {/* --------- survey question section start -------- */}

          {isSubmited ? null : (
            <div className="action">
              <button
                className="btn btn-primary"
                // data-bs-toggle="modal"
                // data-bs-target="#submitSurveyFeedback"
                onClick={surveySubmitHandler}
              >
                Submit Survey
              </button>
            </div>
          )}
        </div>
      ) : (
        <div className="bg-white p-3">
          {surveyAnswers.map((surveyAnswer, index) => {
            return (
              <div className="form-group mb-3" key={index}>
                {/* ----- question section start ------ */}
                <label htmlFor="participating">
                  {surveyAnswer ? surveyAnswer.question : ""}
                </label>
                {surveyAnswer.type == "Rating" ? (
                  <div className="feedback_rating ">
                    <ul className="list_stye_none">
                      {["1", "2", "3", "4", "5"].map((star, index2) => {
                        const isActive =
                          surveyAnswer.answer !== "" &&
                          Number(star) <= Number(surveyAnswer.answer);
                        return (
                          <li
                            key={index2}
                            className={isActive ? "active pe-none" : "pe-none"}
                          >
                            {isActive ? (
                              <span className="material-icons-outlined">
                                star
                              </span>
                            ) : (
                              <span className="material-icons-outlined">
                                star_outline
                              </span>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                ) : (
                  <p className="form-control">{surveyAnswer.answer}</p>
                )}
                {/* ----- question section end ------ */}
              </div>
            );
          })}
          {/* --------- survey question section end -------- */}
        </div>
      )}
      <SurveyFeedbackThanksModal closeModalHandler={closeModalHandler} />
    </div>
  );
};

export default SurveyFeedbackForm;
